import React from 'react';
import { RegionProvider } from './src/context/RegionContext';
require('./node_modules/bootstrap/dist/css/bootstrap.min.css');
require('./src/styles/global.scss');

export const wrapRootElement = ({ element }) => <RegionProvider>{element}</RegionProvider>;

export const onRouteUpdate = ({ location }) => {
  const REGION_DIMENSION = 'region';
  const region = window.___region;

  if (window.gtag && region) {
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      [REGION_DIMENSION]: region,
    });
  }
};
